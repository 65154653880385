<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="OrgAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addOrg">
        <!-- Name -->
        <b-form-group label="Full Name" label-for="full-name">
          <validation-provider
            #default="{ errors }"
            name="fullname"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/]+$/,
            }"
          >
            <b-form-input
              id="fullname"
              v-model="fullname"
              :state="errors.length > 0 ? false : null"
              name="fullname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Email -->
        <b-form-group label="Email" label-for="email">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="email|required"
          >
            <b-form-input
              id="email"
              v-model="email"
              :state="errors.length > 0 ? false : null"
              name="email"
              @blur="handleBlur"
              @keydown.tab="handleTabKey"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Password -->
        <!-- <b-form-group label="Password" label-for="Password">
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
                name="password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
        <!-- Name -->
        <b-form-group label="Organization Name" label-for="organization-name">
          <validation-provider
            #default="{ errors }"
            name="org_name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="org_name"
              v-model="org_name"
              :state="errors.length > 0 ? false : null"
              name="org_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Organization Address"
          label-for="organization-address"
        >
          <validation-provider
            #default="{ errors }"
            name="Organization Address"
            :rules="{ regex: /^[a-zA-Z0-9\s\.\,\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/ }"
          >
            <b-form-input
              id="org_address"
              v-model="org_address"
              name="org_address"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Organization Nick Name"
          label-for="organization-nick-name"
        >
          <validation-provider
            #default="{ errors }"
            name="Organization nickname"
            :rules="{ regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/ }"
          >
            <b-form-input
              id="org_nick_name"
              v-model="org_nick_name"
              name="org_nick_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Industry" label-for="industry">
          <v-select
            v-model="industry"
            label="text"
            taggable
            :options="industry_options"
            autocomplete
            style="width: 100%"
            :reduce="(industry) => industry.value"
          />
        </b-form-group>
        <b-form-group label="Country" label-for="country">
          <v-select
            v-model="country"
            label="text"
            taggable
            :options="country_options"
            autocomplete
            style="width: 100%"
            :reduce="(country) => country.value"
          />
        </b-form-group>
        <b-form-group label="CDR" label-for="cdr">
          <!-- <b-form-select class="" v-model="cdr" :options="cdr_options">
          </b-form-select> -->
          <!-- taggable -->
          <v-select
            v-model="cdr"
            label="text"
            :options="cdr_options"
            @search="loadCdr"
            autocomplete
            :reduce="(cdr) => cdr.value"
          />
          <!-- @option:selected="loadVulnerability" -->
        </b-form-group>
        <b-form-group label="Start Date">
          <flat-pickr
            placeholder="Start Date"
            v-model="fromDate"
            class="form-control"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="my-1"
          size="sm"
        >
          Add
        </b-button>
      </b-form>
    </validation-observer>
    <b-modal
      v-model="openVerifyListModal"
      scrollable
      :no-close-on-backdrop="true"
      ok-only
      ok-variant="secondary"
      size="lg"
    >
      <template #modal-header="{}">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h6 class="mb-0">Organizations associated with this domain</h6>
        </div>
      </template>
      <div v-if="loading">
        <div class="d-flex justify-content-center">
          <b-spinner small />
        </div>
      </div>
      <div class="w-100" v-if="!loading">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <p>
            Domain: <span class="ml-25 font-weight-bold">{{ domain }}</span>
          </p>
        </div>
        <ul v-if="verifiedUser != null && verifiedUser.length > 0">
          <li v-for="comp in verifiedUser" :key="comp._id">
            {{ comp.name }}
          </li>
        </ul>
        <div
          v-else
          class="w-100 d-flex flex-column align-items-center justify-content-center"
          style="height: 100px"
        >
          <feather-icon icon="DatabaseIcon" class="mb-1" size="32" />
          <h5 class="font-weight-bolder">No Organizations</h5>
        </div>
      </div>
      <template #modal-footer>
        <div
          class="w-100 d-flex align-items-center justify-content-center mt-75"
        >
          <b-button
            variant="success"
            size="sm"
            class="mr-50"
            @click="closeModal()"
            >Continue</b-button
          >
          <b-button
            variant="danger"
            size="sm"
            @click="
              () => {
                closeModal();
                closeAdduser();
              }
            "
            >Cancel</b-button
          >
        </div>
      </template>
    </b-modal>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormSelect,
  BAlert,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    vSelect,
    flatPickr,
    BListGroup,
    BListGroupItem,
    BSpinner,
  },
  data() {
    return {
      fullname: "",
      email: "",
      password: "",
      org_name: "",
      org_address: "",
      org_nick_name: "",
      cdr: "",
      cdr_options: [],
      country: null,
      country_options: [],
      industry: null,
      industry_options: [],
      fromDate: null,
      openVerifyListModal: false,
      verifiedUser: [],
      names: [],
      domain: "",
      loading: false,
    };
  },

  created: function () {
    this.loadCountry();
    this.loadIndustry();
  },
  methods: {
    loadCdr: function (search) {
      if (search.length) {
        // loading(true);
        // Load Users here
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/cdr-search" +
            "?name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.cdr_options = [];
          console.log(res.data, "cddddddddrrrrrrrr");
          res.data.map(function (value, key) {
            let a = {
              value: value.org_id,
              text: value.org_name,
              full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.cdr_options.push(a);
          });
        });
      }
    },
    loadCountry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "organization/vciso-countries",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data.data.data, "country details");
        res.data.data.data.map(function (value, key) {
          let a = {
            value: res.data.data.data[key]._id,
            text: res.data.data.data[key].name,
          };
          self.country_options.push(a);
        });
      });
    },
    loadIndustry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "organization/vciso-industries",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "industry details");
        res.data.data.data.map(function (value, key) {
          let a = {
            value: res.data.data.data[key]._id,
            text: res.data.data.data[key].name,
          };
          self.industry_options.push(a);
        });
      });
    },
    handleBlur() {
      const atIndex = this.email.indexOf("@");
      this.veifyUser();
      this.domain = atIndex !== -1 ? this.email.slice(atIndex + 1) : "";
    },
    handleTabKey() {
      const atIndex = this.email.indexOf("@");
      this.veifyUser();
      this.domain = atIndex !== -1 ? this.email.slice(atIndex + 1) : "";
    },
    veifyUser: function () {
      this.openVerifyListModal = true;
      this.loading = true;
      let url = process.env.VUE_APP_BASEURL + "user/list-user-organization";
      if (this.email != "") {
        url = url + "?email=" + this.email;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.verifiedUser = res.data.data;
        this.loading = false;
      });
    },

    closeModal() {
      this.openVerifyListModal = false;
    },
    closeAdduser() {
      this.$router.push({ name: "Organizations" });
    },
    addOrg: function () {
      this.$refs.OrgAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          console.log("success");
          var data;
          if (this.cdr == "") {
            data = {
              fullname: this.fullname,
              email: this.email,
              // password: this.password,
              organization_name: this.org_name,
              org_address: this.org_address,
              org_nick_name: this.org_nick_name,
              country: this.country,
              industry: this.industry,
              starting_month: this.fromDate,
            };
          } else {
            data = {
              fullname: this.fullname,
              email: this.email,
              // password: this.password,
              organization_name: this.org_name,
              org_address: this.org_address,
              org_nick_name: this.org_nick_name,
              cdr_org_id: this.cdr,
              country: this.country,
              industry: this.industry,
              starting_month: this.fromDate,
            };
          }
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "organization/sign-up",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.org_id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Created Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push({ name: "Organizations" });
              // Add new organization to the organizations in store
              this.$store.state.app.organizations.push({
                value: res.data.sso_ref_id,
                text: res.data.org_name,
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          });
        }
      });
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

